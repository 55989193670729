import './errorBoundary.scss';

import LoadingButton from '@mui/lab/LoadingButton';
import { get, uniqueId } from 'lodash';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';

import ErrorImg from '../../../assets/hero-error-with-smoke.svg';
import { useErrorReporting } from '../../../hooks/useErrorReporting/useErrorReporting';
import useRouteStore from '../../../store/routeStore';
import { ErrorPage } from '../../ErrorHandlingLayer/ErrorHandlingLayer';

/**
 * ErrorBoundary
 * This component is used to catch any errors that bubble up from both the router level and the component level.
 *
 * This component is being used in both the new UI and the old UI.
 * We use the useRouteStore hook to determine if the user is on the new UI or the old UI and render the appropriately styled error page.
 */
const ErrorBoundary = () => {
  const { captureException } = useErrorReporting();
  const { isOnNewUIRoute } = useRouteStore();
  const { t } = useTranslation();
  const error = useRouteError();

  const errorMessage = get(error, 'message');
  const errorStack = get(error, 'stack');

  useEffect(() => {
    captureException(errorMessage, errorStack);
  }, [error]);

  const redirectToDashboard = () => {
    window.location.assign('/dashboard/garage');
  };

  return isOnNewUIRoute ? (
    <div className="error-container">
      <div className="error-img">
        <img alt={t('imageAlts.heroErrorWithSmoke')} src={ErrorImg} />
      </div>

      <div className="error-text">
        <h1>{t('errorBoundary.title')}</h1>
        <h2>
          <Trans
            components={[<br key={uniqueId('br-')} />]}
            i18nKey={'errorBoundary.subtitle'}
          />
        </h2>
      </div>

      <div>
        <LoadingButton
          type="button"
          variant="contained"
          onClick={redirectToDashboard}
        >
          {t('errorBoundary.dashboard')}
        </LoadingButton>
      </div>
    </div>
  ) : (
    <ErrorPage />
  );
};

export default ErrorBoundary;
