import './circularProgressFullScreen.css';

import CircularProgress from '@mui/material/CircularProgress';

export const CircularProgressFullScreen = () => {
  return (
    <div className="fullscreen-container">
      <CircularProgress />
    </div>
  );
};
